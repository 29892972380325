import React, { useState, useEffect } from "react";
import axios from 'axios';
import * as Icon from "react-feather";
import { Helmet } from "react-helmet";

import { useForm } from "react-hook-form";

import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";

import Correo from '../Services/Mauloasan/Admin/Correo';
import Frontend from '../Services/Mauloasan/Core/Frontend';

function Contact(){
    const { register, handleSubmit, watch, errors } = useForm();

    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [emailAddress, setEmailAddress] = useState([]);
    const [address, setAddress] = useState([]);
    const [formdata, setFormdata] = useState({
        name: "",
        email: "",
        number: "",
        subject: "",
        message: ""
    });
    const [messageOK, setMessageOK] = useState(false);
    const [message, setMessage] = useState("");
    const [section, setSection] = useState(null);

    const submitHandler = () =>{
        let arrName = formdata.name.split(' ');
        let dataRequest = {
            correoTipo: 'consulta',
            correoContenido: {
                consultaNombreUsuario: arrName[0],
                consultaApellidoUsuario: arrName[1],
                consultaPhoneUsuario: formdata.number,
                consultaEmailUsuario: formdata.email,
                consultaContenido: formdata.message
            }
        }
        Correo.postCorreo(dataRequest, process.env.REACT_APP_ADMINTOKEN).then((data) => {
            if(data.success){
                setMessage(data.data.msg);
                setMessageOK(data.success);
                setFormdata({
                    name: "",
                    email: "",
                    number: "",
                    subject: "",
                    message: ""
                });
            }
        });
    }

    const handleChange = (event) => {
        setFormdata({
            ...formdata,
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    const numberFormatter = (number) =>{
        const phnNumber = number;
        return phnNumber;
    }

    const handleAlerts = (msg) => {
        if(messageOK){
            return (
                <div className="alert alert-success mt-4">
                    {message}
                </div>
            )
        }else{
            return (
                <div className="alert alert-danger mt-4">
                    {msg}
                </div>
            )
        }
        
    }

    useEffect(() => {
        axios.get('/api/contactinfo')
        .then(response =>{
            setPhoneNumbers(response.data.phoneNumbers);
            setEmailAddress(response.data.emailAddress);
            setAddress(response.data.address);
        });

        setTimeout(async () => {
            await Frontend.getFrontend(process.env.REACT_APP_FRONTEND_ID, process.env.REACT_APP_ADMINTOKEN).then(data => {
                if(data.success){
                    if(data.data.detalles){
                        data.data.detalles.map((item, index) => {
                            if(item.FrontendDetalleEstado === 'A'){
                                switch(parseInt(item.FrontendTipoDetalleID)){
                                    case 3:  
                                        setPhoneNumbers(item.FrontendDetalleDescripcion);
                                    break;
                                    case 4:  
                                        setEmailAddress(item.FrontendDetalleDescripcion);
                                    break;
                                    case 5:  
                                        setAddress(item.FrontendDetalleDescripcion);
                                    break;
                                }
                            }
                        });
                    }

                    if(data.success){
                        if(data.data.secciones){
                            data.data.secciones.map((item, index) =>{
                                let currentURL = window.location.href;
                                if(item.frontendSeccionOgUrl === currentURL && item.frontendSeccionEstado === 'A'){
                                    setSection(item);
                                }
                            })
                        }
                    }
                }
            });
        }, 1000);
    }, [])

    return (
        <Layout>
            {section &&  <Helmet>
                <title>Mauloasan | {section.frontendSeccionTitle && section.frontendSeccionTitle}</title>
                {section.frontendSeccionDescripcion && <meta name="description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionKeywords && <meta name="keywords" content={section.frontendSeccionKeywords} />}

                {section.frontendSeccionOgUrl && <meta property="og:url" content={section.frontendSeccionOgUrl} />}
                {section.frontendSeccionOgType && <meta property="og:type" content={section.frontendSeccionOgType} />}
                {section.frontendSeccionTitle && <meta property="og:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta property="og:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionAppID && <meta property="fb:app_id" content={section.frontendSeccionAppID} />}
                {section.frontendSeccionImage && <meta property="og:image" content={section.frontendSeccionImage} />}

                {section.frontendSeccionTwitterCard && <meta name="twitter:card" content={section.frontendSeccionTwitterCard} />}
                <meta name="twitter:site" content="@mauloasan" />
                {section.frontendSeccionTitle && <meta name="twitter:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta name="twitter:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionImage && <meta name="twitter:image" content={section.frontendSeccionImage} />}
            </Helmet>}

            <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <Sectiontitle title="Contact Me"/>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mi-contact-formwrapper">
                                <h4>Get In Touch</h4>
                                <form className="mi-form mi-contact-form" onSubmit={handleSubmit(submitHandler)}>
                                <div className="mi-form-field">
                                    <label htmlFor="contact-form-name">Enter your name*</label>
                                    <input onChange={handleChange} type="text" name="name" id="contact-form-name" 
                                    value={formdata.name} 
                                    ref={register({ 
                                        required: {
                                            value: true,
                                            message: 'This field is required'
                                        }, 
                                        maxLength: {
                                            value: 25,
                                            message: 'this field must have 25 characters maximum'
                                        } 
                                    })}/>
                                    {errors.name && <span>{handleAlerts(errors.name.message)}</span>}
                                </div>
                                <div className="mi-form-field">
                                    <label htmlFor="contact-form-email">Enter your email*</label>
                                    <input onChange={handleChange} type="text" name="email" id="contact-form-email" 
                                    value={formdata.email} 
                                    ref={register({ 
                                        required: {
                                            value: true,
                                            message: 'This field is required'
                                        }, 
                                        pattern: {
                                            value: /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/,
                                            message: 'this field must be a valid email'
                                        } 
                                    })}/>
                                    {errors.email && <span>{handleAlerts(errors.email.message)}</span>}
                                </div>
                                <div className="mi-form-field">
                                    <label htmlFor="contact-form-number">Enter your phone number*</label>
                                    <input onChange={handleChange} type="text" name="number" id="contact-form-number" 
                                    value={formdata.number} 
                                    ref={register({ 
                                        required: {
                                            value: true,
                                            message: 'This field is required'
                                        }, 
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: 'this field must be a valid number'
                                        } 
                                    })}/>
                                    {errors.number && <span>{handleAlerts(errors.number.message)}</span>}
                                </div>
                                <div className="mi-form-field">
                                    <label htmlFor="contact-form-subject">Enter your subject*</label>
                                    <input onChange={handleChange} type="text" name="subject" id="contact-form-subject" 
                                    value={formdata.subject} 
                                    ref={register({ 
                                        required: {
                                            value: true,
                                            message: 'This field is required'
                                        }, 
                                        maxLength: {
                                            value: 25,
                                            message: 'this field must have 25 characters maximum'
                                        } 
                                    })}/>
                                    {errors.subject && <span>{handleAlerts(errors.subject.message)}</span>}
                                </div>
                                <div className="mi-form-field">
                                    <label htmlFor="contact-form-message">Enter your Message*</label>
                                    <textarea onChange={handleChange} name="message" id="contact-form-message" cols="30" rows="6" 
                                    value={formdata.message} 
                                    ref={register({ 
                                        required: {
                                            value: true,
                                            message: 'This field is required'
                                        }, 
                                        maxLength: {
                                            value: 240,
                                            message: 'this field must have 240 characters maximum'
                                        }
                                    })}></textarea>
                                    {errors.message && <span>{handleAlerts(errors.message.message)}</span>}
                                </div>
                                <div className="mi-form-field">
                                    <button className="mi-button" type="submit">Send Mail</button>
                                </div>
                                </form>
                            </div>

                            {messageOK && handleAlerts('')}
                        </div>
                        <div className="col-lg-6">
                            <div className="mi-contact-info">
                                {!phoneNumbers ? null : (
                                <div className="mi-contact-infoblock">
                                    <span className="mi-contact-infoblock-icon">
                                    <Icon.Phone/>
                                    </span>
                                    <div className="mi-contact-infoblock-content">
                                    <h6>Phone</h6>
                                    <p key={phoneNumbers}><a href={numberFormatter(phoneNumbers)}>{phoneNumbers}</a></p>
                                    </div>
                                </div>
                                )}
                                {!emailAddress ? null : (
                                <div className="mi-contact-infoblock">
                                    <span className="mi-contact-infoblock-icon">
                                    <Icon.Mail/>
                                    </span>
                                    <div className="mi-contact-infoblock-content">
                                    <h6>Email</h6>
                                    <p key={emailAddress}><a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
                                    </div>
                                </div>
                                )}
                                {!address ? null : (
                                <div className="mi-contact-infoblock">
                                    <span className="mi-contact-infoblock-icon">
                                    <Icon.MapPin/>
                                    </span>
                                    <div className="mi-contact-infoblock-content">
                                    <h6>Address</h6>
                                    <p>{address}</p>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Contact;
