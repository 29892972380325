import axios from 'axios';

const Correo = {
    postCorreo: (dataRequest, adminToken) => new Promise(
        (resolve, reject) => {
            let link = process.env.REACT_APP_API_ADMIN + '/api/correo/';
            let respuesta = {};
            axios.post(link, dataRequest, {
                headers: {
                    "Authorization": "Bearer " + adminToken,
                    "Content-Type": 'application/json'
                },
                responseType: 'json',
            })
            .then( response => {
                respuesta = response;
                resolve(response.data);
            })
            .catch((err) => {console.log(err)
                respuesta = {
                  data : {
                    msg: err
                  },
                  success: false
                };
                reject(respuesta);
            });
    
            return respuesta;
        }
    )
}
export default Correo