import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Particles from "react-particles-js";
import Layout from "../components/Layout";
import Socialicons from "../components/Socialicons";

import Persona from '../Services/Mauloasan/Admin/Persona';
import Frontend from '../Services/Mauloasan/Core/Frontend';


function Home({lightMode}){ 
    const [information, setInformation] = useState("");
    const [person, setPerson] = useState(null);
    const [section, setSection] = useState(null);
    

    const paramConfig = {
        particles: {
            number: {
                value: 160,
                density: {
                enable: false
                }
            },
            color: {
                value: "#ffffff"
            },
            opacity: {
                value: 0.1
            },
            size: {
                value: 5,
                random: true,
                anim: {
                speed: 4,
                size_min: 0.3
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                random: true,
                speed: 1,
                direction: "top",
                out_mode: "out"
            }
        }
    };

    const paramConfigLight = {
        particles: {
            number: {
                value: 160,
                density: {
                enable: false
                }
            },
            color: {
                value: "#000000"
            },
            opacity: {
                value: 0.1
            },
            size: {
                value: 5,
                random: true,
                anim: {
                speed: 4,
                size_min: 0.3
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                random: true,
                speed: 1,
                direction: "top",
                out_mode: "out"
            }
        }
    };
    useEffect(() =>{
        axios.get('/api/information')
        .then( response => {
            setInformation(response.data);
        })

        setTimeout(async () => {
            await Persona.getPerson(process.env.REACT_APP_CLIENTE_ID, process.env.REACT_APP_ADMINTOKEN).then(data => {
                if(data.success){
                    setPerson(data.data);
                }
            });

            await Frontend.getFrontend(process.env.REACT_APP_FRONTEND_ID, process.env.REACT_APP_ADMINTOKEN).then(data => {
                if(data.success){
                    if(data.data.secciones){
                        data.data.secciones.map((item, index) =>{
                            let currentURL = window.location.href;
                            if(item.frontendSeccionOgUrl === currentURL && item.frontendSeccionEstado === 'A'){
                                setSection(item);
                            }
                        })
                    }
                }
            });
        }, 1000);
    }, [])
    return (
        <Layout>
            {section &&  <Helmet>
                <title>Mauloasan | {section.frontendSeccionTitle && section.frontendSeccionTitle}</title>
                {section.frontendSeccionDescripcion && <meta name="description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionKeywords && <meta name="keywords" content={section.frontendSeccionKeywords} />}

                {section.frontendSeccionOgUrl && <meta property="og:url" content={section.frontendSeccionOgUrl} />}
                {section.frontendSeccionOgType && <meta property="og:type" content={section.frontendSeccionOgType} />}
                {section.frontendSeccionTitle && <meta property="og:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta property="og:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionAppID && <meta property="fb:app_id" content={section.frontendSeccionAppID} />}
                {section.frontendSeccionImage && <meta property="og:image" content={section.frontendSeccionImage} />}

                {section.frontendSeccionTwitterCard && <meta name="twitter:card" content={section.frontendSeccionTwitterCard} />}
                <meta name="twitter:site" content="@mauloasan" />
                {section.frontendSeccionTitle && <meta name="twitter:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta name="twitter:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionImage && <meta name="twitter:image" content={section.frontendSeccionImage} />}
            </Helmet>}
        
            <div className="mi-home-area mi-padding-section">
                <Particles className="mi-home-particle" params={lightMode? paramConfigLight : paramConfig} />
                <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-12">
                    <div className="mi-home-content">
                        <h1>
                            Hi, I am <span className="color-theme">{person && person.personaNombres} {person && person.personaApellidos}</span>
                        </h1>
                        <p>{information.aboutContent}</p>
                        <Socialicons bordered />
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </Layout>
    );
}

export default Home;
