import axios from 'axios';

const Frontend = {
    getFrontend: (frontendID, adminToken) => new Promise(
        (resolve, reject)   => {
            frontendID = parseInt(frontendID); 
            let link = process.env.REACT_APP_API_CORE + '/api/frontend/' + frontendID;
            let respuesta = null;
            axios.get(link, {
                headers: {
                    "Authorization": "Bearer " + adminToken
                }
            })
            .then( response => {
                resolve(response.data);
            })
            .catch((err) => {
                respuesta = {
                    data : {
                        msg: err
                    },
                    success: false
                };
                reject(respuesta);
            });

            return respuesta
        }
    )
}

export default Frontend;