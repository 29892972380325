import React, {useState, useEffect} from "react";
import axios from 'axios';
import LineIcon from 'react-lineicons';

import Frontend from '../Services/Mauloasan/Core/Frontend';

function Socialicons(props){
    const [socialLinks, setSocialLinks] = useState({});
    const [frontend, setFrontend] = useState(null);
    const [twitter, setTwitter] = useState(null);
    const [facebook, setFacebook] = useState(null);
    const [instagram, setInstagram] = useState(null);

    useEffect(() => {
        axios.get('/api/information')
        .then(response =>{
            setSocialLinks(response.data.socialLinks);
        });

        setTimeout(async () => {
            await Frontend.getFrontend(process.env.REACT_APP_FRONTEND_ID, process.env.REACT_APP_ADMINTOKEN).then(data => {
                if(data.success){
                    setFrontend(data.data); 
    
                    if(data.data.detalles){
                        data.data.detalles.map((item, index) => {
                            if(item.FrontendDetalleEstado === 'A'){
                                switch(parseInt(item.FrontendTipoDetalleID)){
                                    case 7:  
                                        setTwitter(item.FrontendDetalleDescripcion);
                                    break;
                                    case 8:  
                                        setFacebook(item.FrontendDetalleDescripcion);
                                    break;
                                    case 9:  
                                        setInstagram(item.FrontendDetalleDescripcion);
                                    break;
                                }
                            }
                        });
                    }
                }
            });
        }, 1000);
        
    }, [])

    return (
        <ul className={props.bordered ? 'mi-socialicons mi-socialicons-bordered' : 'mi-socialicons'}>
        {!facebook ? null : <li>
            <a rel="noopener noreferrer" target="_blank" href={facebook}>
            <LineIcon name="facebook"/>
            </a>
        </li>}
        {!twitter ? null : <li>
            <a rel="noopener noreferrer" target="_blank" href={twitter}>
            <LineIcon name="twitter"/>
            </a>
        </li>}
        {!socialLinks.pinterest ? null : <li>
            <a rel="noopener noreferrer" target="_blank" href={socialLinks.pinterest}>
            <LineIcon name="pinterest"/>
            </a>
        </li>}
        {!socialLinks.behance ? null : <li>
            <a rel="noopener noreferrer" target="_blank" href={socialLinks.behance}>
            <LineIcon name="behance"/>
            </a>
        </li>}
        {!socialLinks.linkedin ? null : <li>
            <a rel="noopener noreferrer" target="_blank" href={socialLinks.linkedin}>
            <LineIcon name="linkedin"/>
            </a>
        </li>}
        {!socialLinks.dribbble ? null : <li>
            <a rel="noopener noreferrer" target="_blank" href={socialLinks.dribbble}>
            <LineIcon name="dribbble"/>
            </a>
        </li>}
        {!instagram ? null : <li>
            <a rel="noopener noreferrer" target="_blank" href={instagram}>
            <LineIcon name="instagram"/>
            </a>
        </li>}
        {!socialLinks.github ? null : <li>
            <a rel="noopener noreferrer" target="_blank" href={socialLinks.github}>
            <LineIcon name="github"/>
            </a>
        </li>}
        </ul>
    );
}

export default Socialicons;
