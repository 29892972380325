import React from "react";

function DetailResume(props) {
  
  return (
    <ul>
    {props.details.map((item, index) => (
        <li key={item.id}>{item.detail}</li>
    ))}
    </ul>
  );
};

export default DetailResume;
