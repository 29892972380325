import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import BlogsView from "../components/BlogsView";
import Pagination from "../components/Pagination";

import Frontend from '../Services/Mauloasan/Core/Frontend';

function Blogs() {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);
    const [section, setSection] = useState(null);

    useEffect(() => {
        setTimeout(async () => {
            await Frontend.getFrontend(process.env.REACT_APP_FRONTEND_ID, process.env.REACT_APP_ADMINTOKEN).then(data => {
                if(data.success){
                    if(data.data.secciones){
                        data.data.secciones.map((item, index) =>{
                            let currentURL = window.location.href;
                            if(item.frontendSeccionOgUrl === currentURL && item.frontendSeccionEstado === 'A'){
                                setSection(item);
                            }
                        })
                    }
                }
            });
        }, 1000);

        let mounted = true;
        axios.get("/api/blog").then(response => {
            if(mounted){
                setPosts(response.data);
            }
        });
        return () => mounted = false;
    }, [posts]);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    }

    return (
        <Layout>
            {section &&  <Helmet>
                <title>Mauloasan | {section.frontendSeccionTitle && section.frontendSeccionTitle}</title>
                {section.frontendSeccionDescripcion && <meta name="description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionKeywords && <meta name="keywords" content={section.frontendSeccionKeywords} />}

                {section.frontendSeccionOgUrl && <meta property="og:url" content={section.frontendSeccionOgUrl} />}
                {section.frontendSeccionOgType && <meta property="og:type" content={section.frontendSeccionOgType} />}
                {section.frontendSeccionTitle && <meta property="og:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta property="og:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionAppID && <meta property="fb:app_id" content={section.frontendSeccionAppID} />}
                {section.frontendSeccionImage && <meta property="og:image" content={section.frontendSeccionImage} />}

                {section.frontendSeccionTwitterCard && <meta name="twitter:card" content={section.frontendSeccionTwitterCard} />}
                <meta name="twitter:site" content="@mauloasan" />
                {section.frontendSeccionTitle && <meta name="twitter:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta name="twitter:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionImage && <meta name="twitter:image" content={section.frontendSeccionImage} />}
            </Helmet>}

            <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <Sectiontitle title="Recent Blogs" />
                    <BlogsView blogs={currentPosts} />
                    {!(posts.length > postsPerPage) ? null : (
                        <Pagination
                        className="mt-50"
                        itemsPerPage={postsPerPage}
                        totalItems={posts.length}
                        paginate={paginate}
                        currentPage={currentPage}
                        />
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Blogs;
