import React, { useState, useEffect } from "react";
import axios from 'axios';
import TrackVisibility from "react-on-screen";
import { Helmet } from "react-helmet";

import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from '../components/Smalltitle';
import Layout from "../components/Layout";
import Progress from "../components/Progress";
import Resume from "../components/Resume";

import Frontend from '../Services/Mauloasan/Core/Frontend';

function Resumes(){
    const [skills, setSkills] = useState([]);
    const [workingExperience, setWorkingExperience] = useState([]);
    const [educationExperience, setEducationExperience] = useState([]);
    const [section, setSection] = useState(null);

    useEffect(() =>{
        setTimeout(async () => {
            await Frontend.getFrontend(process.env.REACT_APP_FRONTEND_ID, process.env.REACT_APP_ADMINTOKEN).then(data => {
                if(data.success){
                    if(data.data.secciones){
                        data.data.secciones.map((item, index) =>{
                            let currentURL = window.location.href;
                            if(item.frontendSeccionOgUrl === currentURL && item.frontendSeccionEstado === 'A'){
                                setSection(item);
                            }
                        })
                    }
                }
            });
        }, 1000);

        axios.get('/api/skills')
        .then(response =>{
            setSkills(response.data);
        })
        axios.get('/api/experience')
        .then(response =>{
            setWorkingExperience(response.data.workingExperience);
            setEducationExperience(response.data.educationExperience);
        })
    }, [])

    return (
        <Layout>
            {section &&  <Helmet>
                <title>Mauloasan | {section.frontendSeccionTitle && section.frontendSeccionTitle}</title>
                {section.frontendSeccionDescripcion && <meta name="description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionKeywords && <meta name="keywords" content={section.frontendSeccionKeywords} />}

                {section.frontendSeccionOgUrl && <meta property="og:url" content={section.frontendSeccionOgUrl} />}
                {section.frontendSeccionOgType && <meta property="og:type" content={section.frontendSeccionOgType} />}
                {section.frontendSeccionTitle && <meta property="og:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta property="og:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionAppID && <meta property="fb:app_id" content={section.frontendSeccionAppID} />}
                {section.frontendSeccionImage && <meta property="og:image" content={section.frontendSeccionImage} />}

                {section.frontendSeccionTwitterCard && <meta name="twitter:card" content={section.frontendSeccionTwitterCard} />}
                <meta name="twitter:site" content="@mauloasan" />
                {section.frontendSeccionTitle && <meta name="twitter:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta name="twitter:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionImage && <meta name="twitter:image" content={section.frontendSeccionImage} />}
            </Helmet>}
            
            <div className="mi-skills-area mi-section mi-padding-top">
                <div className="container">
                    <Sectiontitle title="My Skills" />
                    <div className="mi-skills">
                        <div className="row mt-30-reverse">
                            {skills.map(skill => (
                                <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                                <Progress title={skill.title} percentage={skill.value} />
                                </TrackVisibility>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <Sectiontitle title="Resume" />
                    <Smalltitle title="Working Experience" icon="briefcase" />
                    <div className="mi-resume-wrapper">
                        {workingExperience.map(workingExp => (
                        <Resume key={workingExp.id} resumeData={workingExp} />
                        ))}
                    </div>
                    <div className="mt-30"></div>
                    <Smalltitle title="Educational Qualifications" icon="book" />
                    <div className="mi-resume-wrapper">
                        {educationExperience.map(educatonExp => (
                        <Resume key={educatonExp.id} resumeData={educatonExp}/>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Resumes;
