import Mock from "../mock";

const database = {
    information: {
    name: 'Devid Chester',
    aboutContent: "I am a web developer. I can provide clean code and pixel perfect design. I also make website more & more interactive with web animations.",
    age: 30,
    phone: '',
    nationality: 'Ecuadorian',
    language: 'Spanish, English',
    email: '',
    address: '121 King Street, Melbourne, Australia',
    freelanceStatus: 'Available',
    socialLinks: {
        facebook: 'https://facebook.com',
        twitter: 'https://twitter.com',
        pinterest: '',
        behance: '',
        linkedin: '',
        dribbble: '',
        github: 'https://github.com'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/MauricioLoayzaS-En.pdf'
    },
    services: [
    {
        title: "Design and development of websites",
        icon: 'code',
        details: "Creation of personal and business websites, with modern designs and adaptable to all types of electronic devices."
    },
    {
        title: "Design And Development Of e-commerce",
        icon: 'credit-cards',
        details: "Electronic commerce is the present and the future of business. I will help you with the creation of a friendly and robust e-commerce to make your business grow."
    },
    {
        title: "Custom Applications",
        icon: 'mobile',
        details: "Each business has its own needs which must be covered and supported by an application that makes your work easier, I will take care of building a customized application according to your needs."
    }
    ],
    reviews: [
    {
        id: 1,
        content: "In the process that we have carried out to improve our online store and implement an import manager, the help provided by Mauricio has been fundamental.",
        author: {
            name: 'Serimtec S.A',
            designation: 'Distributor of technological products'
        }
    },
    {
        id: 2,
        content: "A great element in our work team, with a projection to great professional things",
        author: {
            name: 'Laura Campoverde',
            designation: 'Project manager - Grupo Link'
        }
    },
    {
        id: 3,
        content: "Mauricio's analytical skills helped automate the catechetical control processes, unientis is a very good project",
        author: {
            name: 'Silvia Perez',
            designation: 'businesswoman'
        }
    }
    ],
    skills: [
        {
            title: "Javascript",
            value: 95
        },
        {
            title: "PHP",
            value: 95
        },
        {
            title: "Java",
            value: 30
        },
        {
            title: ".Net",
            value: 30
        },
        {
            title: "Mysql",
            value: 95
        },
        {
            title: "Oracle",
            value: 60
        },
        {
            title: "Postgesql",
            value: 60
        },
        {
            title: "zend >=2 (php)",
            value: 80
        },
        {
            title: "Symfony >=2 (php)",
            value: 80
        },
        {
            title: "Silex >=1.83 (php)",
            value: 80
        },
        {
            title: "Laravel >=4 (php)",
            value: 90
        },
        {
            title: "Jquery (Javascript)",
            value: 90
        },
        {
            title: "AngularJS >=2 (Javascript)",
            value: 50
        },
        {
            title: "ReactJS >=16 (Javascript)",
            value: 50
        },
        {
            title: "HTML5",
            value: 95
        },
        {
            title: "CSS3",
            value: 90
        },
        {
            title: "Bootstrap >=3",
            value: 80
        },
        {
            title: "Git",
            value: 90
        },
        {
            title: "waterfall methodology for project management",
            value: 90
        },
        {
            title: "Incremental methodology for project management",
            value: 90
        },
        {
            title: "Scrum methodology for project management",
            value: 90
        },
        {
            title: "ASD methodology for project management",
            value: 60
        },
        {
            title: "Kanban methodology for project management",
            value: 90
        },
    ],
    portfolios: [
        {
            id: 1,
            title: "Amazon Frontlines",
            subtitle: "Website for this nonprofit, here I has been working in mantence and  adding new functions",
            imageUrl: "/images/portfolio/af.png",
            largeImageUrl: ["/images/portfolio/af-large.png"],
            url: 'https://amazonfrontlines.org'
        },
        {
            id: 2,
            title: "Landing page video promo Claro",
            subtitle: "For Grupo link I made this site, if this site is opened in a mobile with claro operator, the mobile get a benefice",
            imageUrl: "/images/portfolio/promo-claro.png",
            largeImageUrl: ["/images/portfolio/promo-claro-large.png"],
            url: 'https://www.miclaro.com.ec/video-promo/web/'
        },
        {
            id: 3,
            title: "CNL Ecuador",
            subtitle: "Web site for this organization.",
            imageUrl: "/images/portfolio/cnl.png",
            largeImageUrl: ["/images/portfolio/cnl-large.png"],
            url: 'http://cnlecuador.com/'
        },
        {
            id: 4,
            title: "Farmaenlace",
            subtitle: "For Grupo Link I made this Web aplication to can do polls",
            imageUrl: "/images/portfolio/farmaenlace.png",
            largeImageUrl: ["/images/portfolio/farmaenlace-large.png"],
        },
        {
            id: 5,
            title: "Paga tu factura Mi Claro",
            subtitle: "For Grupo Link I made this web aplication to can pay the invoice about movile service to Claro's clients",
            imageUrl: "/images/portfolio/paga-factura.png",
            largeImageUrl: ["/images/portfolio/paga-factura-large.png"],
            url: 'https://miclaro.com.ec/pagatufactura',
        },
        {
            id: 6,
            title: "Peso Saludable",
            subtitle: "WebSite about the businnes of Peso Saludable",
            imageUrl: "/images/portfolio/peso-saludable.png",
            largeImageUrl: ["/images/portfolio/peso-saludable-large.png"],
            url: 'https://peso-saludable.com/public/',
        },
        {
            id: 7,
            title: "Celca",
            subtitle: "WebSite of celca Magazine",
            imageUrl: "/images/portfolio/celca.png",
            largeImageUrl: ["/images/portfolio/celca-large.png"],
            url: 'http://celca.ec/'
        },
        {
            id: 8,
            title: "App Claro website",
            subtitle: "For Grupo Link. This site describe the App of Claro.",
            imageUrl: "/images/portfolio/app-claro.png",
            largeImageUrl: ["/images/portfolio/app-claro-large.png"],
            url: 'https://www.miclaro.com.ec/personas/web/app_dev.php/minisitio/',
        },
        {
            id: 9,
            title: "ClickTesis",
            subtitle: "Website for this business.",
            imageUrl: "/images/portfolio/click-tesis.png",
            largeImageUrl: ["/images/portfolio/click-tesis-large.png"],
            url: 'http://clicktesis.com/',
        },
        {
            id: 10,
            title: "Serimtec",
            subtitle: "WebSite about the businnes of Peso Saludable",
            imageUrl: "/images/portfolio/serimtec.png",
            largeImageUrl: ["/images/portfolio/serimtec-large.png"],
            url: 'https://www.serimtec.com/'
        },
        {
            id: 11,
            title: "Import",
            subtitle: "For Serimtec. This web aplication can to control the imports of this businnes",
            imageUrl: "/images/portfolio/import.png",
            largeImageUrl: ["/images/portfolio/import-large.png"],
        },
    ],
    experience: {
        workingExperience: [
            {
                id: 1,
                year: "2019 - Present",
                position: "IT Lead",
                company: "Amazon Frontlines",
                details:[
                    {
                        id : 1,
                        detail : "Website maintenance amazonfrontlines.org.",
                    },
                    {
                        id : 2,
                        detail : "Maintenance of the website Allianceceibo.org.",
                    },
                    {
                        id : 3,
                        detail : "Development of landing pages for campaigns.",
                    },
                    {
                        id : 4,
                        detail : "Hosting administration of the different websites.",
                    },
                    {
                        id : 5,
                        detail : "Internal and external technical support.",
                    },
                    {
                        id : 6,
                        detail : "Verification and implementation of new technologies at the service of the organization.",
                    },
                    {
                        id : 7,
                        detail : "Periodically report the movement of the websites to those interested.",
                    },
                    {
                        id : 8,
                        detail : "Mailchimp support and maintenance for campaigns.",
                    },
                    {
                        id : 9,
                        detail : "Support and maintenance of the Zoho platform for donor control.",
                    },
                    {
                        id : 10,
                        detail : "Support and maintenance of the Classy platform for donor control.",
                    },
                    {
                        id: 11,
                        detail: "Administration of email accounts of the organization."
                    }
                ] 
            },
            {
                id: 2,
                year: "2016 - 2019",
                position: "Application Development Software",
                company: "Grupo Link",
                details: [
                    {
                        id : 12,
                        detail : "Website maintenance miclaro.com.ec.",
                    },
                    {
                        id : 13,
                        detail : "Analysis for the development of new features of the website miclaro.com.ec",
                    },
                    {
                        id : 14,
                        detail : "Development of new features of the miclaro.com.ec website.",
                    },
                    {
                        id : 15,
                        detail : "Analysis of the functionalities to be programmed in the mini-specific sites.",
                    },
                    {
                        id : 16,
                        detail : "Development of mini specific sites, for promotions and new services.",
                    },
                    {
                        id : 17,
                        detail : "Analysis and design of the internal audit system of farmaenlace.",
                    },
                    {
                        id : 18,
                        detail : "Development of the internal audit system of farmaenlace.",
                    },
                ]
            },
            {
                id: 3,
                year: "2014 - 2016",
                position: "Technical assistant manager, web developer, webmaster, community manager.",
                company: "Serimtec PC Ecuador S.A​",
                details: [
                    {
                        id : 19,
                        detail : "Creation and maintenance of corporate website.",
                    },
                    {
                        id : 20,
                        detail : "Creation and maintenance of web application for import control of the company.",
                    },
                    {
                        id : 21,
                        detail : "Synchronizing the website with the ERP system of the company.",
                    },
                    {
                        id : 22,
                        detail : "Consultation and research of the main features of the new products.",
                    },
                    {
                        id : 23,
                        detail : "Commercial customer support.",
                    },
                ]
            },
            {
                id: 4,
                year: "2014 - 2014",
                position: "Junior PHP Programmer, Web Developer",
                company: "Krifa S.A​",
                details: [
                    {
                        id : 24,
                        detail : "Development of queries in the Web application to demarcate.",
                    },
                    {
                        id : 25,
                        detail : "Loan Calculator credit union.",
                    },
                ]
            },
            {
                id: 5,
                year: "2013 - 2014",
                position: "echnical support.",
                company: "Ocitel S.A​",
                details: [
                    {
                        id : 26,
                        detail : "Development and maintenance of ERP module Tax Fragata.",
                    },
                    {
                        id : 27,
                        detail : "Development and maintenance of crossing function within the module accounts receivables ERP Fragata​ .",
                    },
                ]
            },
            {
                id: 6,
                year: "2011 - 2013",
                position: "Junior PHP Programmer, Web Developer",
                company: "Lecansa S.A​",
                details: [
                    {
                        id : 28,
                        detail : "Maintenance of project machines \"Más Tecnología\" of the municipality of Guayaquil with public schools in the city.",
                    },
                    {
                        id : 29,
                        detail : "Maintenance of database E-Learning used by the project \"Más Tecnología\" of the municipality of Guayaquil with public schools in the city.",
                    },
                ]
            },
            {
                id: 7,
                year: "2010 - 2010",
                position: "call center",
                company: "Easynet S.A​",
                details: [
                    {
                        id : 30,
                        detail : "First line customer support domestic area, they called for problems in their internet service.",
                    },
                ]
            }
        ],
        educationExperience: [
            {
                id: 1,
                year: "2009 - 2016",
                graduation: "Systems engineer",
                university: "Guayaquil University",
                details: "Career that allowed to deepen the knowledge of the management and development of computer applications, IT infrastructure projects, etc. in a professional way, based on the demands of the contemporary labor market."
            },
            {
                id: 2,
                year: "2002 - 2007",
                graduation: "Bachelor of Science",
                university: "Calicuchima College",
                details: "Basic and secondary training in computer science specialty."
            },
        ]
    },
    blogs: [
        {
            id: 1,
            title: 'Markdown & Html supported blog.',
            featuredImage: '/images/blog-image-1.jpg',
            filesource: '../../blog/markdown-html-supported-blog.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 2,
            title: 'Installing NodeJS on your device.',
            featuredImage: '/images/blog-image-2.jpg',
            filesource: '../../blog/installing-nodejs-on-your-device.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 3,
            title: 'UI/UX design starter with Adobe XD.',
            featuredImage: '/images/blog-image-3.jpg',
            filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 4,
            title: 'Boost your post for increasing sales.',
            featuredImage: '/images/blog-image-4.jpg',
            filesource: '../../blog/boost-your-post-for-increasing-sales.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 5,
            title: 'Difference between GatsbyJS & NextJS.',
            featuredImage: '/images/blog-image-5.jpg',
            filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 6,
            title: 'How to choose javascript framework for project.',
            featuredImage: '/images/blog-image-6.jpg',
            filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 7,
            title: 'Web automation with python language.',
            featuredImage: '/images/blog-image-7.jpg',
            filesource: '../../blog/web-automation-with-python-language.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 8,
            title: 'Time to use latest technology for creating a website.',
            featuredImage: '/images/blog-image-8.jpg',
            filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 9,
            title: 'Think out of the box.',
            featuredImage: '/images/blog-image-9.jpg',
            filesource: '../../blog/think-out-of-the-box.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 10,
            title: 'Trending designs in 2020.',
            featuredImage: '/images/blog-image-1.jpg',
            filesource: '../../blog/trending-designs-in-2020.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 11,
            title: 'How to get 10k instagram followers?',
            featuredImage: '/images/blog-image-2.jpg',
            filesource: '../../blog/how-to-get-10k-instagram-followers.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 12,
            title: 'What NodeJS can do?',
            featuredImage: '/images/blog-image-3.jpg',
            filesource: '../../blog/what-nodejs-can-do.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 13,
            title: 'Futures of javascript.',
            featuredImage: '/images/blog-image-4.jpg',
            filesource: '../../blog/future-of-javascript.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 14,
            title: 'Popular javascript library in 2020.',
            featuredImage: '/images/blog-image-5.jpg',
            filesource: '../../blog/popular-javascript-library-in-2020.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        },
        {
            id: 15,
            title: 'Promrammers must read books.',
            featuredImage: '/images/blog-image-6.jpg',
            filesource: '../../blog/programmers-must-read-books.md',
            createDay: "20",
            createMonth: 'February',
            createYear: "2020"
        }
    ],
    contactInfo: {
        phoneNumbers: ['+012-3456-7891', '+012-3456-7892'],
        emailAddress: ['admin.sitename@example.com', 'info.sitename@example.com'],
        address: "121 King Street, Melbourne, Victoria 3000, Australia"
    }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});