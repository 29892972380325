import axios from "axios";
import FsLightbox from "fslightbox-react";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import ProgressiveImage from 'react-progressive-image';
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";
import Service from "../components/Service";
import Testimonial from "../components/Testimonial";

import Persona from '../Services/Mauloasan/Admin/Persona';
import Frontend from '../Services/Mauloasan/Core/Frontend';

const imgMe = process.env.REACT_APP_API_ADMIN + '/img/cliente/' + process.env.REACT_APP_CLIENTE_ID + '/yo.jpg';

function About() {
    const [toggler, setToggler] = useState(false);
    const [information, setInformation] = useState("");
    const [services, setServices] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [person, setPerson] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [address, setAddress] = useState(null);
    const [section, setSection] = useState(null);


    const sliderSettings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 6000,
        paufrontendSeccionnHover: true,
        adaptiveHeight: true,
        responsive: [
        {
            breakpoint: 768,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            },
        },
        ],
    };

    const handleToggler = (event) => {
        setToggler({
            toggler: !toggler,
        });
    };

    useEffect(() => {
        axios.get("/api/information").then((response) => {
            setInformation(response.data);
        });
        axios.get("/api/services").then((response) => {
            setServices(response.data);
        });
        axios.get("/api/reviews").then((response) => {
            setReviews(response.data);
        });

        setTimeout(async () => {
            await Persona.getPerson(process.env.REACT_APP_CLIENTE_ID, process.env.REACT_APP_ADMINTOKEN).then(data => {
                if(data.success){
                    setPerson(data.data);
                }
            });

            await Frontend.getFrontend(process.env.REACT_APP_FRONTEND_ID, process.env.REACT_APP_ADMINTOKEN).then(data => {
                if(data.success){
                    if(data.data.detalles){
                        data.data.detalles.map((item, index) => {
                            if(item.FrontendDetalleEstado === 'A'){
                                switch(parseInt(item.FrontendTipoDetalleID)){
                                    case 3:  
                                        setPhone(item.FrontendDetalleDescripcion);
                                    break;
                                    case 4:  
                                        setEmail(item.FrontendDetalleDescripcion);
                                    break;
                                    case 5:  
                                        setAddress(item.FrontendDetalleDescripcion);
                                    break;
                                }
                            }
                        });
                    }

                    if(data.data.secciones){
                        data.data.secciones.map((item, index) =>{
                            let currentURL = window.location.href;
                            if(item.frontendSeccionOgUrl === currentURL && item.frontendSeccionEstado === 'A'){
                                setSection(item);
                            }
                        })
                    }
                }
            });
        }, 1000);
    }, []);

    return (
        <Layout>
            {section &&  <Helmet>
                <title>Mauloasan | {section.frontendSeccionTitle && section.frontendSeccionTitle}</title>
                {section.frontendSeccionDescripcion && <meta name="description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionKeywords && <meta name="keywords" content={section.frontendSeccionKeywords} />}

                {section.frontendSeccionOgUrl && <meta property="og:url" content={section.frontendSeccionOgUrl} />}
                {section.frontendSeccionOgType && <meta property="og:type" content={section.frontendSeccionOgType} />}
                {section.frontendSeccionTitle && <meta property="og:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta property="og:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionAppID && <meta property="fb:app_id" content={section.frontendSeccionAppID} />}
                {section.frontendSeccionImage && <meta property="og:image" content={section.frontendSeccionImage} />}

                {section.frontendSeccionTwitterCard && <meta name="twitter:card" content={section.frontendSeccionTwitterCard} />}
                <meta name="twitter:site" content="@mauloasan" />
                {section.frontendSeccionTitle && <meta name="twitter:title" content={section.frontendSeccionTitle} />}
                {section.frontendSeccionDescripcion && <meta name="twitter:description" content={section.frontendSeccionDescripcion} />}
                {section.frontendSeccionImage && <meta name="twitter:image" content={section.frontendSeccionImage} />}
            </Helmet>}
            
            <div className="mi-about-area mi-section mi-padding-top">
                <div className="container">
                    <Sectiontitle title="About Me" />
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mi-about-image">
                                <ProgressiveImage
                                    src={imgMe}
                                    placeholder="/images/about-image-placeholder.png"
                                >
                                    {src => <img src={src} alt="aboutimage" onClick={() => handleToggler(!toggler)} />}
                                </ProgressiveImage>
                                <span className="mi-about-image-icon">
                                    <Icon.ZoomIn />
                                </span>
                                <FsLightbox
                                    toggler={toggler}
                                    sources={[imgMe]}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mi-about-content">
                                <h3>
                                I am <span className="color-theme">{person && person.personaNombres} {person && person.personaApellidos}</span>
                                </h3>
                                <p>
                                I am a frontend web developer. I can provide clean code and
                                pixel perfect design. I also make website more & more
                                interactive with web animations.
                                </p>
                                <ul>
                                {!information.name ? null : (
                                    <li>
                                    <b>Full Name</b> {person && person.personaNombres} {person && person.personaApellidos}
                                    </li>
                                )}
                                {!information.age ? null : (
                                    <li>
                                    <b>Age</b> {information.age} Years
                                    </li>
                                )}
                                {!phone ? null : (
                                    <li>
                                    <b>Phone</b> {phone}
                                    </li>
                                )}
                                {!information.nationality ? null : (
                                    <li>
                                    <b>Nationality</b> {information.nationality}
                                    </li>
                                )}
                                {!information.language ? null : (
                                    <li>
                                    <b>Languages</b> {information.language}
                                    </li>
                                )}
                                {!email ? null : (
                                    <li>
                                    <b>Email</b> {email}
                                    </li>
                                )}
                                {!address ? null : (
                                    <li>
                                    <b>Address</b> {address}
                                    </li>
                                )}
                                {!information.freelanceStatus ? null : (
                                    <li>
                                    <b>Freelance</b> {information.freelanceStatus}
                                    </li>
                                )}
                                </ul>
                                <a href={information.cvfile} className="mi-button">
                                Download CV
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mi-service-area mi-section mi-padding-top">
                <div className="container">
                <Sectiontitle title="Services" />
                <div className="mi-service-wrapper">
                    <div className="row mt-30-reverse">
                    {services.map((service) => (
                        <div className="col-lg-4 col-md-6 col-12 mt-30" key={service.title}>
                            <Service content={service} />
                        </div>
                    ))}
                    </div>
                </div>
                </div>
            </div>
            <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                <Sectiontitle title="Reviews" />
                <div className="row justify-content-center">
                    <div className="col-12">
                    <Slider className="mi-testimonial-slider" {...sliderSettings}>
                        {reviews.map((review) => (
                        <Testimonial key={review.id} content={review} />
                        ))}
                    </Slider>
                    </div>
                </div>
                </div>
            </div>
        </Layout>
    );
}

export default About;
