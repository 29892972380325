import axios from 'axios';

const Persona = {
    getPerson: (personaID, adminToken) => new Promise(
        (resolve, reject) => {
            personaID = parseInt(personaID);
            let link = process.env.REACT_APP_API_ADMIN + '/api/personas/' + personaID;
            let respuesta = {};
            axios.get(link, {
                headers: {
                    "Authorization": "Bearer " + adminToken
                }
            })
            .then( response => {
                respuesta = {
                    data : response.data,
                    success: true
                };
                resolve(respuesta);
            })
            .catch((err) => {
                respuesta = {
                  data : {
                    msg: err
                  },
                  success: false
                };
                reject(respuesta);
            });
    
            return respuesta;
        }
    )
}
export default Persona